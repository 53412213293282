import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useMultiRef, useState } from '@upstatement/react-hooks';
import { Image, Link, RichText, SEO } from '@src/components';
import { Arrow } from '@src/svgs';
import { className } from '@src/utils';
import ScrollObserver from '@src/utils/ScrollObserver';
import styles from './styles/index.module.scss';

const HomePage = ({
  data: {
    page: { featuredGames, gamesTitle, headshot, intro, seo, title },
  },
}) => {
  const isSSR = typeof window === 'undefined';

  const observerRef = useRef(null);
  const [linkRefs, setLinkRef] = useMultiRef();

  const [index, setIndex] = useState(0);

  const onScroll = entries => {
    const index = entries.findIndex(
      (entry, i) => i === entries.length - 1 || entry.percentages.window.bound < 1,
    );

    setIndex(index);
  };

  useEffect(() => {
    if (!isSSR) {
      const observer = new ScrollObserver(onScroll, {});
      observer.observe(...linkRefs.current);
      observerRef.current = observer;
    }
  }, []);

  return (
    <Fragment>
      <SEO seo={seo} title={title} />
      <section className={styles.intro}>
        <aside className={styles.headshotWrapper}>
          <div className={styles.headshot}>
            <Image value={headshot} />
          </div>
        </aside>
        <div className={styles.introContent}>
          <h1 className={styles.introTitle}>{title}</h1>
          <RichText content={intro} />
        </div>
      </section>
      <section className={styles.games}>
        <h2 className={styles.gamesTitle}>{gamesTitle}</h2>
        <div className={styles.slider}>
          <div className={styles.sliderInner}>
            <div className={styles.previewContainer}>
              <div className={styles.preview}>
                {featuredGames.map((game, i) => (
                  <div
                    key={game._id}
                    {...className(styles.previewInner, index === i && styles.active)}>
                    <Image className={styles.previewImage} value={game.preview} />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.gamesRail}>
              <ul className={styles.gameList}>
                {featuredGames.map((game, i) => (
                  <li key={game._id} {...className(styles.gameItem, index === i && styles.active)}>
                    <Link
                      ref={setLinkRef(i)}
                      className={styles.gameLink}
                      to={`/games/${game.slug.current}`}>
                      <div className={styles.gamePreview}>
                        <div className={styles.gamePreviewInner}>
                          <Image className={styles.gamePreviewImage} value={game.preview} />
                        </div>
                      </div>
                      <div className={styles.gameMeta}>
                        <p className={styles.gameLabel}>New</p> {/* TODO: implement new logic */}
                        <p className={styles.gameTitle}>{game.title}</p>
                        <p className={styles.gameSummary}>{game.summary}</p>
                        <div className={styles.gameArrow}>
                          <Arrow />
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.cta}>
          <Link className={styles.allLink} to="/games">
            See all games
            <span className={styles.inlineArrow}>
              <span className={styles.inlineArrowIcon}>
                <Arrow />
              </span>
            </span>
          </Link>
        </div>
      </section>
    </Fragment>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
  query HomePageQuery {
    page: sanityHomePage {
      title
      intro: _rawIntro(resolveReferences: { maxDepth: 10 })
      headshot {
        altText
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      gamesTitle
      featuredGames {
        ...GameTease
      }
      seo {
        ...SanitySeo
      }
    }
  }
`;

export default HomePage;
