import { on } from '@src/utils';

const bindValueRange = (val, min, max) => Math.min(max, Math.max(min, val));

export class ObservableEntry {
  /**
   *
   * @param {object} entry
   * @param {HTMLElement} entry.ref
   * @param {number} entry.elPercentage
   * @param {number} entry.windowPercentage
   * @param {DOMRect} entry.boundingClientRect
   */
  constructor({ ref, elPercentage, windowPercentage, boundingClientRect }) {
    this.ref = ref;

    this.percentages = {
      el: {
        unbound: elPercentage,
        bound: bindValueRange(elPercentage, 0, 1),
      },
      window: {
        unbound: windowPercentage,
        bound: bindValueRange(windowPercentage, 0, 1),
      },
    };

    this.boundingClientRect = boundingClientRect;
  }
}

class ScrollObserver {
  /**
   * Creates a new scroll observer.
   *
   * @param {function} callback the callback function to call on scroll with the observed element
   * @param {object} options an options object to use for the callback
   * @param {number} [options.visibleAt=1] a value 0-1 that describes the required amount of visibility to consider the
   * element "fully-scrolled" through
   * @param {number} [options.breakpoint] the minimum width needed to use the scroll observer
   */
  constructor(callback, options) {
    this.options = options || {};
    this.callback = callback;

    this.listeners = [];
    /**
     * @type {HTMLElement[]}
     */
    this.entries = [];

    this.onScroll = this.onScroll.bind(this);
    this._isHidden = this._getIsHidden();

    this.init();
  }

  init() {
    this.onScroll();
    this.listeners.push(
      on(window, 'scroll', this.onScroll.bind(this)),
      on(window, 'resize', this.onResize.bind(this)),
    );
  }

  observe(...entries) {
    this.entries.push(...entries);
    this.onScroll();
  }

  destroy() {
    this.listeners.forEach(off => {
      off();
    });
  }

  _getIsHidden() {
    return window.innerWidth < (this.options.breakpoint || 0);
  }

  onResize() {
    this._isHidden = this._getIsHidden();
    this.onScroll();
  }

  onScroll() {
    if (!this._isHidden) {
      const visibleAt = typeof this.options.visibleAt === 'number' ? this.options.visibleAt : 1;

      const windowHeight = window.innerHeight;

      const entries = this.entries.map(el => {
        const rect = el.getBoundingClientRect();

        const windowVisibleHeight = windowHeight * visibleAt;
        const unboundWindowPercentage =
          (windowHeight - rect.top - rect.height) / windowVisibleHeight;

        const elVisibleHeight = rect.height * visibleAt;
        const unboundElPercentage =
          1 - (rect.bottom - (rect.height - elVisibleHeight) - windowHeight) / elVisibleHeight;

        return new ObservableEntry({
          boundingClientRect: rect,
          elPercentage: unboundElPercentage,
          windowPercentage: unboundWindowPercentage,
          ref: el,
        });
      });

      this.callback(entries);
    }
  }
}

export default ScrollObserver;
